import React from 'react';
import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer';
import Feature from './Feature';
import Benefit from './Benefit';
import Tick from './tick 1.png';
// import Wrong from './wrong.png';
import './style.css';
import ScrollToTop from './ScrollToTop';
import Fdata from './FeatureData';
import Bdata from './Benefitdata';
import Carousel from './Carousel';
import FAQ from './FAQ';
import QueryForm from './Queryform';
// import Partner from './Partners';
import MACOVIDEO from './videos/MACO HMS VIDEO (2).mp4';
import Poster from './images/MACO HMS VIDEO (2).png';
import ClientCarousel from './Clientscarousel';
import Partner1 from './images/axisroom.png';
import Partner2 from './images/Razorpay.svg';
import Partner3 from './images/aws.png' ;
import { Helmet } from 'react-helmet';




const Home = () => {
    return(
        <> 
          <Helmet>
          <script type="application/ld+json">
          {`
{
	"@context": "http://schema.org/",
	"@type": "Organization",
	"name": "MacoTech",
	"logo": "https://macotech.in/static/media/Logo.6f2024a76448a5497e9d.png",
	"url": "https://macotech.in/",
	"address": {
		"@type": "PostalAddress",
		"streetAddress": "First Floor, Bungalow Plot 2, West Patel Nagar",
		"addressLocality": "New Delhi",
		"addressRegion": "Delhi",
		"postalCode": "110008",
		"addressCountry": "India"
	},
	"sameAs": ["https://www.facebook.com/macotechsoftware/", "https://www.instagram.com/macotechsoftware/", "https://www.linkedin.com/company/macotechsoftware/", "https://x.com/macotechin"]
}
  `}
</script>
          </Helmet>
         
            <Header/>
            <Navbar />
{/* hero section */}
           
            <div className='hero-section'>
           <div className='hero-section-left'>
            <h1 >HOTEL MANAGER</h1>
            <h2>India’s No.1 Property Management Solution</h2>
            <h3>Hotel Manager is a fully integrated cloud based Property Management Solution that can increase your hospitality business & reduce costs & inefficiency</h3>
            {/* <a href="#getenquiry"><button>Book Demo</button></a> */}
            <a href="#knowmore"><button>Know More</button></a>
           </div>
           {/* <iframe width="580" height="350" src="https://www.youtube.com/embed/y0YDHwt4AEY" title="All-in-One Hotel Management Software" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
           <div className='video-container'>
           <video  controls  loop muted poster={Poster}><source src={MACOVIDEO} type="video/mp4"/></video>
           </div>
          </div>
         {/* About us Section */}
         <div className='about-us'>
            <div><h1>Revolutionize your business operations with HOTEL MANAGER, The leading Property Management Solution in India</h1></div>
            <h2>Hotel Manager India’s No.1 Property Management Solution offers wide range of features designed to streamline hotel operations and enhance guest experiences. </h2>
            <h3>Here are some key benefits and features that can make a difference for your business :</h3>
         </div>

         {/* feature section */}
        
        <section id="Feature"/>
       <div className='feature-section'>
          <h1 className='section-heading'>Key Features</h1>
          <div>
          <Feature img={Fdata[0].img} desc={Fdata[0].desc} heading={Fdata[0].heading} para={Fdata[0].para}/>
          <Feature img={Fdata[1].img} desc={Fdata[1].desc} heading={Fdata[1].heading} para={Fdata[1].para}/>
          </div>
          <div>
          <Feature img={Fdata[2].img} desc={Fdata[2].desc} heading={Fdata[2].heading} para={Fdata[2].para}/>
          <Feature img={Fdata[3].img} desc={Fdata[3].desc} heading={Fdata[3].heading} para={Fdata[3].para}/>
          </div>
          <div>
          <Feature img={Fdata[4].img} desc={Fdata[4].desc} heading={Fdata[4].heading} para={Fdata[4].para}/>
          <Feature img={Fdata[5].img} desc={Fdata[5].desc} heading={Fdata[5].heading} para={Fdata[5].para}/>
          </div>
          <div>
          <Feature img={Fdata[6].img} desc={Fdata[6].desc} heading={Fdata[6].heading} para={Fdata[6].para}/>
          <Feature img={Fdata[7].img} desc={Fdata[7].desc} heading={Fdata[7].heading} para={Fdata[7].para}/>
          </div>
          <div>
          <Feature img={Fdata[8].img} desc={Fdata[8].desc} heading={Fdata[8].heading} para={Fdata[8].para}/>
          <Feature img={Fdata[9].img} desc={Fdata[9].desc} heading={Fdata[9].heading} para={Fdata[9].para}/>
          </div>
          <div>
          <Feature img={Fdata[10].img} desc={Fdata[10].desc} heading={Fdata[10].heading} para={Fdata[10].para}/>
          <Feature img={Fdata[11].img} desc={Fdata[11].desc} heading={Fdata[11].heading} para={Fdata[11].para}/>
          </div>
          <div>
          <Feature img={Fdata[12].img} desc={Fdata[12].desc} heading={Fdata[12].heading} para={Fdata[12].para}/>
          <Feature img={Fdata[13].img} desc={Fdata[13].desc} heading={Fdata[13].heading} para={Fdata[13].para}/>
          </div>
          <div>
          <Feature img={Fdata[14].img} desc={Fdata[14].desc} heading={Fdata[14].heading} para={Fdata[14].para}/>
          <Feature img={Fdata[15].img} desc={Fdata[15].desc} heading={Fdata[15].heading} para={Fdata[15].para}/>
          </div>
          {/* <Feature img={Fdata[3].img} desc={Fdata[3].desc} heading={Fdata[3].heading} para={Fdata[3].para}/> */}
      
       </div>   
          
          
        {/* Free Demo Request */}

        <div className='demo-section'>
            <h1>Say goodbye to manual processes and experience the difference now!</h1>
            <div></div>
            <a href="#getenquiry"><button>Register for a Free Demo </button></a>
        </div>

        {/* Key Benefit */}
        <section id="Benefits"/>

        <div className='feature-section'>
        <h1 className='section-heading'>Key Benefits</h1>
        <div>
        <Benefit img={Bdata[0].img} desc={Bdata[0].desc} heading={Bdata[0].heading} para={Bdata[0].para}/>
        <Benefit img={Bdata[1].img} desc={Bdata[1].desc} heading={Bdata[1].heading} para={Bdata[1].para}/>
       </div>
       <div>
        <Benefit img={Bdata[2].img} desc={Bdata[2].desc} heading={Bdata[2].heading} para={Bdata[2].para}/>
        <Benefit img={Bdata[3].img} desc={Bdata[3].desc} heading={Bdata[3].heading} para={Bdata[3].para}/>
       </div>
       <div>
        <Benefit img={Bdata[4].img} desc={Bdata[4].desc} heading={Bdata[4].heading} para={Bdata[4].para}/>
        <Benefit img={Bdata[5].img} desc={Bdata[5].desc} heading={Bdata[5].heading} para={Bdata[5].para}/>
       </div>
       <div>
        <Benefit img={Bdata[6].img} desc={Bdata[6].desc} heading={Bdata[6].heading} para={Bdata[6].para}/>
        <Benefit img={Bdata[7].img} desc={Bdata[7].desc} heading={Bdata[7].heading} para={Bdata[7].para}/>
       </div>
    
        </div>

        {/* get started section */}
        <div className='demo-section'>
            <h1>Enhance your hotel's operations and revenue with the most advanced & cloud-based Property Management Solution.</h1>
            <a href="#getenquiry"><button>Get Started Now !</button></a>
        </div>
      
        {/* Why US Section */}
        <section id='knowmore'></section>
        <div className='why-us'>
           <div className='why-video-container'>
           <video  controls  loop muted poster={Poster}><source src={MACOVIDEO} type="video/mp4"/></video>
           </div>
           {/* <iframe width="500" height="300" src="https://www.youtube.com/embed/y0YDHwt4AEY" title="All-in-One Hotel Management Software" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
           <div>
            <h1>Why Choose HOTEL MANAGER ?</h1>
            <h2>Choose Hotel Manager as your Hotel Management Solution because we offer a comprehensive solution that streamlines hotel operations, enhances guest experience, and maximize revenue.</h2>
            <ul>
            <div>
                <li>Reliability</li>
                <li>Good Guidance</li>
                </div>
                <div>
                <li>Scalability</li>
                <li>Trust & Safety</li>
                </div>
                <div>
                <li>Longevity</li>
                <li>Price Guarantee</li>
                </div>
            </ul>
            </div>
        
        </div>
        
        {/* Pricing Plan Section */}

        <table className='pricing-plan'>
            <thead>
                <tr>
                    <th className='table-heading heading-1' style={{color:'white'}} >Plan & Pricing</th>
                    <th>
                    
                      <table className='header-2'>
                         <tr>
                          <td className='table-heading' style={{color:'white'}} >Standard Plan</td>
                         </tr>
                         <tr>
                          <td><s>Rs. 2500/- (Per Month )</s></td>
                         </tr>
                         <tr>
                          <td >Rs. 1500/- ( per month )</td>
                         </tr>
                      </table>
                     
                    </th>
                    
                 </tr>
             </thead>
         
            <tr className='table-row'>
                <td>Guest Management</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='odd-row table-row'>
                <td>Smart Search Panel	
</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='table-row'>
                <td>Reservation</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='odd-row table-row'>
                <td>Registration</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='table-row'>
                <td>Room Services</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='odd-row table-row'>
                <td>Housekeeping</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='table-row'>
                <td>Accounts & GST</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='odd-row table-row'>
                <td>MIS Reports	</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='table-row'>
                <td>Inventory Management (Stock & Purchase)</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='odd-row table-row'>
                <td>User & Sub User Logins</td>
                <td><img src={Tick} alt='included'></img></td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            <tr className='table-row'>
               <td>Restaurant Management</td>
                {/* <td><img src={Wrong} alt='included'></img></td> */}
                <td className='special-price'>
                <table>
                    <tr>
                     <td><s>Rs. 1500/- </s></td>
                    </tr>
                    <tr>
                     <td style={{color:'#044CAC'}}>Rs. 1000/- ( per month )</td>
                    </tr>
                 </table>
                </td>
               
                {/* <td>Quote As Requirement</td> */}
                {/* <td>Quote As Requirement</td> */}
            </tr>
            <tr className='odd-row table-row'>
            <td>Banquet Management</td>
                <td className='special-price'>
                <table className='table-2'>
                    <tr>
                     <td><s>Rs. 1500/- </s></td>
                    </tr>
                    <tr>
                     <td style={{color:'#044CAC'}}>Rs. 1000/- ( per month )</td>
                    </tr>
                 </table>
                </td>
                
                {/* <td>Quote As Requirement</td> */}
                {/* <td>Quote As Requirement</td> */}
            </tr>
            <tr className=' table-row'>
            <td>Mobile App One User (Admin)</td>
                <td className='special-price'>
                <table>
                    <tr>
                     <td><s>Rs. 1000/- </s></td>
                    </tr>
                    <tr>
                     <td style={{color:'#044CAC'}}>Rs. 600/- ( per month )</td>
                    </tr>
                 </table>
                </td>
                {/* <td>Quote As Requirement</td> */}
                {/* <td>Quote As Requirement</td> */}
            </tr>
            <tr className='odd-row table-row'>
            <td>Financial Accounts</td>
                <td className='special-price'>
                <table>
                    <tr>
                     <td><s>Rs. 1000/- </s></td>
                    </tr>
                    <tr>
                     <td style={{color:'#044CAC'}}>Rs. 600/- ( per month )</td>
                    </tr>
                 </table>
                </td>
                {/* <td><img src={Tick} alt='included'></img></td> */}
            </tr>
            
            <tr className='quote-row table-row'>
                <td>Website + Booking Engine</td>
                <td style={{color:'#044CAC'}}><a href='#getenquiry'>Get A Quote</a></td>
                {/* <td>Quote As Requirement</td> */}
            </tr>
            <tr className='quote-row odd-row table-row'>
                <td>Channel Manager</td>
                <td style={{color:'#044CAC'}}><a href='#getenquiry'>Get A Quote</a></td>
                {/* <td>Quote As Requirement</td> */}
            </tr>
            
           



        </table>

        {/* Client Section */}
        <section id="Client"/>
        <div className='client-section'> 
         <h1>Our Clients</h1>
         <div></div>
        </div>

      

       <div className='clients-section-div'>

       <ClientCarousel/>

       </div>






       

        {/* testimonial section */}
        <div className='client-section'> 
         <h1>Testimonials</h1>
         <div></div>
        </div>
        <div className='quotaion-image'>
          <Carousel/>
          </div>
         {/* <div>
            <img  alt='Hotel'></img>
            <div>
               <h2>I have been using eZee FrontDesk for 5 years and i love using it. Over the years, the software has upgraded magnificently into a complex yet user friendly with various useful features added periodically. Now , I can’t wait to see what comes next. </h2>
               <div>
                <h2>Manish Gupta</h2>
                <h3>7-Sun Motels & Resorts</h3>
               </div>
               <div>
                {/* circle */}
               {/* </div> */}
              
            {/* </div>
         </div> */} 


         {/* Our Partners */}

         <div className='client-section'> 
         <h1>Our Partners</h1>
         {/* <h5 style={{fontWeight:'500', color:'#044CAC'}}>Empowering hotels with our exceptional network of trusted partners.</h5> */}
         <div></div>
        </div>

        <div className='partners-images'>
        <img src={Partner1} alt='Partner 1' />
        <img src={Partner2} alt='Partner 2' />
        <img src={Partner3} alt='Partner 2' />

        </div>

        {/* <Partner/> */}


         {/* FAQ Section */}
         <div className='client-section'> 
         <h1>FAQ's</h1>
         <div></div>
        </div>
          <FAQ/>
         {/* <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      <span class='fw-bold' >Q.1.</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span class='fw-bold'>Q.2. </span>Lorem Ipsum is simply dummy text of the printing and typesetting industry ?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      <span class='fw-bold' >Q.3.</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span class='fw-bold'>Q.2. </span>Lorem Ipsum is simply dummy text of the printing and typesetting industry ?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span class='fw-bold'>Q.2. </span>Lorem Ipsum is simply dummy text of the printing and typesetting industry ?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span class='fw-bold'>Q.2. </span>Lorem Ipsum is simply dummy text of the printing and typesetting industry ?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span class='fw-bold'>Q.2. </span>Lorem Ipsum is simply dummy text of the printing and typesetting industry ?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span class='fw-bold'>Q.2. </span>Lorem Ipsum is simply dummy text of the printing and typesetting industry ?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span class='fw-bold'>Q.2. </span>Lorem Ipsum is simply dummy text of the printing and typesetting industry ?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
</div> */}

{/* Get Touch Portion */}
<section id='getenquiry' ></section>
    <div className='client-section'> 
         <h1>Contact Us</h1>
         <div></div>
        </div>
<div class='get-touch'> 
    {/* <div class='get-align'>
    <p class='message'>Send  a message</p>
    <h1 class='get-heading'>Get in Touch</h1>
    <p class='get-context'>We will respond to your message as soon as possible </p>
    <input type='text' placeholder='Name' class='name-field'/><br></br>
    <input type='text' placeholder='email' class='email-field'/><br></br>
    <input type='integer' placeholder='Phone no.' class='phone-field'/><br></br>
    <textarea rows='10' coloumn='30' name='comment' class='comment-box' ></textarea><br></br>
    <button class='send-button'>Send a message</button>
    </div> */}
    <QueryForm />
     <div class='map'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.302682104367!2d77.15759331048585!3d28.65065437555332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03d4d60b4c97%3A0x9af4817dfe5d6c9e!2sMACOTECH%20SOFTWARE%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1720006042873!5m2!1sen!2sin" width="600" height="500"  allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='map-size' title='MACOTECH SOFTWARE PRIVATE LTD >'></iframe>

     {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.302631765129!2d77.15758607457353!3d28.650655883259166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d036fccf90297%3A0x1c262c20e33091b8!2sMOHINDRA%20ARORA%20AND%20CO!5e0!3m2!1sen!2sin!4v1718265048597!5m2!1sen!2sin" width="500" height="350"  allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='MohindraMap'></iframe> */}
     </div>
  </div>
      
      <ScrollToTop/>

            <Footer/>
            
        </>
        

    );
}

export default Home ;