import React, { useState } from "react";
import './style.css';
import { MdOutlineMail , MdCall} from "react-icons/md" ;
import { PiLineVerticalLight } from "react-icons/pi";
import { FaFacebook,FaLinkedin,FaInstagram } from "react-icons/fa";
import {  BsTwitterX } from "react-icons/bs";
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";



const Header =() => {
  const [formData, setFormData] = useState({
    hotelname:'',
    name: '',
    phonenumber:'',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  

    emailjs.send(
      'service_87doh2f',
      'template_xnsbn5r',
      {
        ...formData,
        to_email: ('sales@macotech.in','macotechin@gmail.com'), // Ensure this matches the email address in your EmailJS template
      },
      'bQKzl1otP4Ydee43Y'
    )
    .then((response) => {
        console.log(response)
      setStatus('SUCCESS');
      setFormData({
        hotelname:'',
        name: '',
        phonenumber:'',
        email: '',
        message: '',
      });
    })
    .catch((error) => {
      setStatus('FAILED');
      console.error('Email sending error:', error);
    });
  };

    return(
        <>
<div class='header '>
  
  <div class='left'>
 
  <div>
   <MdCall class='headericons call'/>
   <p>+91 8800228539</p> 
  </div>
  
  <div>
   <MdOutlineMail class='headericons'/>
   <p>sales@macotech.in</p> 
  </div>

  </div>
   
  <div class='right'>
  <a href="#Book-demo-form"><button className="header-button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Book Demo</button></a>

  {/* <!-- Button trigger modal --> */}
{/* <button type="button" class="btn btn-primary" >
  Launch static backdrop modal
</button> */}

{/* <!-- Modal --> */}
<div  class="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title  " id="staticBackdropLabel">Book Demo</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {/* <div className="query-form-container"> */}
      {/* <h1 class='get-heading'>Get in Touch</h1> */}
      <form className="query-form" onSubmit={handleSubmit}>
      <div className="form-group">
          <label htmlFor="name"> Hotel Name</label>
          <input
            type="text"
            id="hotelname"
            name="hotelname"
            value={formData.hotelname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name"> Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Your Phone No.</label>
          <input
            type="text"
            id="phonenumber"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Your Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
        {status === 'SUCCESS' && navigate('/thankyou')}
        {status === 'FAILED' && <p className="error-message">Sorry, something went wrong. Please try again.</p>}
      </form>
      {/* </div> */}
      </div>
      
    </div>
  </div>
</div>
  {/* <button className="header-button">Book Demo</button> */}
  <p>Connect with us</p>
  <PiLineVerticalLight class='line' />
  <a href="https://www.facebook.com/macotechsoftware/" target="_blank" rel="noreferrer"><FaFacebook class='headericons-right'/></a>
  <a href="https://www.x.com/macotechin" target="_blank" rel="noreferrer"><BsTwitterX class='headericons-right'/></a>
  <a href="https://www.linkedin.com/company/macotechsoftware/" target="_blank" rel="noreferrer" ><FaLinkedin class='headericons-right'/></a>
  <a href="https://www.instagram.com/macotechsoftware/" target="_blank" rel="noreferrer"><FaInstagram class='headericons-right'/></a>
  </div>
</div>
        </>
    )
}


export default Header ;