import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import Client1 from './clientimage/client20.png';
import Client2 from './clientimage/client22.png';
import Client3 from './clientimage/client21.png';
import Client4 from './clientimage/client17.png';
import Client5 from './clientimage/client25.png';

const testimonials = [
  { img:Client1 ,name: '- Mr. Reddy, Hotel Manager', text: " Hotel Manager has revolutionized our hotel operations. The user-friendly interface and excellent customer support make it indispensable. Highly recommend to any hotelier! " },
  { img:Client2   ,name: '- Ms. Priyanka , Owner', text: " Since switching to Hotel Manager, our booking and housekeeping processes have become seamless. The real-time analytics are a game-changer! "  },
  { img:Client3   ,name: '- General Manager.', text: " Hotel Manager integration with OTAs boosted our bookings significantly. The mobile app is a great tool for on-the-go management.  " },
  { img:Client4   ,name: '- Lucky', text: " Efficient, reliable, and easy to use. Hotel Manager has streamlined our entire hotel management system, making our staff more productive and guests happier. "  },
  { img:Client5  ,name: '- Vishakha', text: " The best investment we've made for our hotel. Hotel Manager features and support are unmatched. It's helped us improve guest satisfaction and operational efficiency. "  },
];

const Carousel = () => {
  const [index, setIndex] = useState(0);
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (


    <div className="carousel">
      <button className="prev" onClick={handlePrev}>&#10094;</button>
      <div className="carousel-container">
        <div className="carousel-items" style={{ transform: `translateX(${-index * 100}%)` }}>
          {testimonials.map((testimonial, i) => (
            <div className="testimonial-card" key={i}>
              <img src={testimonial.img} alt='' className='client-image'></img>
              <div>
              <h3 className="testimonial-text">"{testimonial.text}"</h3>
              <h2 className="client-name">{testimonial.name}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="next" onClick={handleNext}>&#10095;</button>
    </div>
  );
};

export default Carousel;
