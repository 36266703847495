import React from 'react'
import Header from './Header';
import Footer from './Footer';
import Navbar from './Navbar';
import './style.css' ;
import QueryForm from './Queryform';
import { MdOutlineMail , MdCall} from "react-icons/md" ;
import { MdLocationOn } from "react-icons/md";
import ScrollToTop from './ScrollToTop';

function Contact() {
    return(
      
        <>
       
    <Header />
   <Navbar/>
   
        <div class='contact-hero'>
        <div>
          <h1 class='service-heading'>Contact Us</h1>
          {/* <p class='service-home-link'><span >Home</span> Contact us</p> */}
        </div>
        </div>
    <div class='contact-row-1'>
     <div class='contact-infocard'>
    
      <h1>Contact Information</h1>
      <div class='contact-line'></div>
      <ul class='contact-card'>
      <div>
      <MdCall style={{width:'30px', height:'30px', marginTop:'10px'}}/><li>+91 8800228539</li>
      </div>
      <div><MdOutlineMail style={{width:'30px' , height:'30px', marginTop:'10px'}}/><li>sales@macotech.in</li> 
      </div>  
      <div><MdLocationOn style={{width:'30px' ,height:'30px', marginTop:'10px'}}/><li class='address'>Bunglow Plot No. 2, First Floor West Patel Nagar, Opp. Metro Pillar No.-229 New Delhi - 110008</li>  
      </div>
      </ul>

     </div>
     
     <QueryForm />
    {/* <div class='get-touch'> 
     <div class='get-align'>
     <p class='message'>Send  a message</p>
     <h1 class='get-heading'>Get in Touch</h1>
     <p class='get-context'>We will respond to your message as soon as possible </p>
     <input type='text' placeholder='Name' class='name-field'/><br></br>
     <input type='text' placeholder='email' class='email-field'/><br></br>
     <input type='integer' placeholder='Phone no.' class='phone-field'/><br></br>
      <textarea rows='10' coloumn='30' name='comment' class='comment-box' ></textarea><br></br>
     <button class='send-button'>Send a message</button>
     </div>

    </div> */}
   </div>
   
   <div class='contact-map'>
    <h1>Find us on Google Map</h1>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.302682104367!2d77.15759331048585!3d28.65065437555332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03d4d60b4c97%3A0x9af4817dfe5d6c9e!2sMACOTECH%20SOFTWARE%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1720006042873!5m2!1sen!2sin" width="1100" height="350" className='map-size' allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='MACOTECH SOFTWARE PRIVATE LTD>'></iframe>
     {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.302631765129!2d77.15758607457353!3d28.650655883259166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d036fccf90297%3A0x1c262c20e33091b8!2sMOHINDRA%20ARORA%20AND%20CO!5e0!3m2!1sen!2sin!4v1718265048597!5m2!1sen!2sin" width="1100" height="350"  allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='MohindraMap'></iframe> */}
     </div>

     <ScrollToTop/>
<Footer/>
      </>
    );
}

export default Contact ;