import React from 'react'
import Header from './Header';
import Footer from './Footer';
import Blogs from './Blogs';
// import Blogcard from './Blogcard';
// import { IoIosSearch } from "react-icons/io";
import Navbar from './Navbar';
import ScrollToTop from './ScrollToTop';
import { Link } from 'react-router-dom';



const Blog = () => {
    return(
        <>
        <Header/>
        <Navbar />
        
<div class='blog-hero'>
    <div>
      <h1 class='service-heading'>Blog</h1>
      {/* <p class='service-home-link'><span >Home</span>  Blog</p> */}
    </div>
   
  </div>
  <div class='blog-container'>

     <div class='blogs-alignment'>    
      <Blogs />
      
     </div>

  <aside class='blog-sidebar'>
    {/* <div class='blog-search'>
      <input type='text' placeholder='Search' />
      <IoIosSearch class='blog-search-icon'/>
    </div> */}
    {/* <div class='category-card-section'>
      <h2 class="blog-category">Category</h2>
      <hr class="horizontal-line"></hr>
      <ul class='blog-category-list'>
        <li>Business</li>
        <li>Companies Act</li>
        <li>Consultant</li>
        <li>Gst </li>
        <li>Finance </li>
        <li>Accounting and Tax</li>
        <li>Business</li>
        <li>Legal</li>
        <li>Foreign Investment</li>
        <li>Meeting</li>
        <li>Partnership firm</li>
        <li>Startup services</li>
        <li>Others</li>
        <li>Companies Act</li>     
      </ul>
    </div> */}
    
    <div class='recent-post-section'>
      <h2 class="blog-category">Recent Post</h2>
      <div class="horizontal-line"></div>
      <ul class='blog-category-list'>
      <Link to="/What_is_Hotel_Property_Management_Solution"><li>What is Hotel Property Management Solution ?</li></Link>
        {/* <li>How to Hire Chartered Accountant ?</li>
        <li>How to Hire Chartered Accountant ?</li>
        <li>How to Hire Chartered Accountant ? </li> */}
        
      </ul>
    </div>

    {/* <div class='recent-post-section'>
      <h2 class="blog-category">Important updates</h2>
      <hr class="horizontal-line"></hr>
      <ul class='blog-category-list'>
        <li>How to Hire Chartered Accountant ?</li>
        <li>How to Hire Chartered Accountant ?</li>
        <li>How to Hire Chartered Accountant ?</li>
        <li>How to Hire Chartered Accountant ? </li>
        
      </ul>
    </div> */}


   </aside>

</div>
{/* <div class="recent-blogs-portion">
   <h1 class='blog-home-head'>Related Blogs</h1>
   <div class='blog-home-align'  >
    <Blogcard />
    <Blogcard />
    <Blogcard />
    
   </div>
  </div> */}

  <Footer/>
  <ScrollToTop/>
        </>
    );
}

export default Blog ;