import React from "react";
import Home from "./Home";
import './style.css';

// import Benefit from './pages/About';
// import Feature from "./pages/Services";
// import Client from "./pages/Client";
import Blog from "./Blog.js"; 
import Contact from "./Contact";
import BlogContent from "./ContentBlog_1.js";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
// import Feature from "./Feature.js";
import Thankyoupage from "./Thankyoupage.js";



function App() {
    return(
<>
<Router >
  <Routes>
    <Route  path='/' element={<Home />} />   
    <Route  path ='/blog' element={<Blog/>} />
    <Route   path='/contactus' element={<Contact/>} />
    <Route path='/What_is_Hotel_Property_Management_Solution' element={<BlogContent/>}/>
    <Route path="/thankyou" element={<Thankyoupage />} />
  </Routes>
</Router>

</>
    );
}

export default App;